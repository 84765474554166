import React, { Component } from "react";
import "./Style/App.css";
import Hero from "./Components/Hero";
import Footer from "./Components/Footer";
import { data } from "./Data/data";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data,
    };
  }

  getWebsiteData() {
    console.log(
      "👋 Hey there, curious web explorer! Looks like you've discovered the secret chamber of console messages. Here's a joke to brighten your day: \n\nWhy do programmers always mix up Christmas and Halloween? \nBecause Oct 31 === Dec 25! 😄 \n\nThanks for stopping by, and happy debugging!",
    );
  }

  componentDidMount() {
    this.getWebsiteData();
  }

  render() {
    return (
      <div className="App">
        <Hero data={this.state.websiteData.main} />
        <Footer data={this.state.websiteData.main} />
      </div>
    );
  }
}

export default App;
