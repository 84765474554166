import React, { Component } from "react";
import TextInputWithButton from "./Ai";
import "../Style/App.css";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = { revealed: true };
  }

  render() {
    const name = this.props.data?.name;
    const occupation = this.props.data?.occupation;
    const description = this.props.data?.description;

    return (
      <header id="home">
        <div className="row banner">
          <div className="banner-text">
            <h1 className="responsive-headline">
              Hi{" "}
              <span role="img" aria-label="Hello" className="wave">
                👋
              </span>
              , I'm {name}.
            </h1>
            <h3>
              I'm a <span>{occupation}</span>. {description}.
            </h3>
            <p
              className="game"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              I love{" "}
              <span role="img" aria-label="Game" className="hero-emoji">
                🎮
              </span>{" "}
              ,{" "}
              <span role="img" aria-label="Coffee" className="hero-emoji">
                ☕
              </span>{" "}
              &{" "}
              <span role="img" aria-label="Math" className="hero-emoji">
                📚
              </span>
              !
            </p>
          </div>
          <div className="chat-section">
            <TextInputWithButton />
          </div>
          <div>
            <blockquote>
              Through the profound and elegant language of mathematics, Euler's
              identity unites the fundamental constants of nature with
              remarkable simplicity and harmony.
            </blockquote>
            <p>
              <img
                id="equation"
                src="/img/equation.png"
                alt="Euler's identity"
              />
            </p>
          </div>
        </div>
      </header>
    );
  }
}

export default Hero;
