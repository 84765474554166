export const data = {
  websiteData: {
    main: {
      name: "Shahin",
      lastname: "Zanbaghi",
      fullname: "Shahin Zanbaghi",
      occupation: "Senior Software Engineer",
      description:
        "I love learning and improving my skills by taking on new challenges. I'm passionate about finding creative solutions and delivering great results. When I'm not coding, you can find me playing video games or enjoying a good cup of coffee",
      image: "/img/profile-pic-shahin.jpeg",
      encryptedEmail: "c2hhaGluQHphbmJhZ2hpLmNvbQ==",
      email: "shahin[at]zanbaghi.com",
      website: "https://zanbaghi.com",
      doh: "https://dns.zanbaghi.com",
      skills:
        "JavaScript, Typescript, ReactJS, VueJS, NodeJS, Express, Solidity, XSLT, PHP, WordPress, MongoDB, MySQL, Azure, AWS, GCP, Python, Flask, Java, C / C++.",
    },
  },
};
