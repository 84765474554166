import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { skills: undefined, email: undefined };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    fetch("https://api.zanbaghi.com")
      .then((res) => res.json())
      .then((res) => {
        this.setState({ skills: res?.skills, email: res?.email });
      })
      .catch((error) => {
        // show error message
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  render() {
    let skills = this.props.data ? this.props.data.skills : "";
    let email = this.props.data ? this.props.data.email : "";

    if (this.state.skills && this.state.email) {
      skills = this.state.skills.join(", ");
      email = this.state.email;
    }

    return (
      <section id="about">
        <div
          className="row"
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <div className="two columns">
            <a href="https://www.linkedin.com/in/shzanbaghi/" target="_blank">
              <img
                className="linkedin-img"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYxIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOSA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0tMTEgMTloLTN2LTExaDN2MTF6bS0xLjUtMTIuMjY4Yy0uOTY2IDAtMS43NS0uNzktMS43NS0xLjc2NHMuNzg0LTEuNzY0IDEuNzUtMS43NjQgMS43NS43OSAxLjc1IDEuNzY0LS43ODMgMS43NjQtMS43NSAxLjc2NHptMTMuNSAxMi4yNjhoLTN2LTUuNjA0YzAtMy4zNjgtNC0zLjExMy00IDB2NS42MDRoLTN2LTExaDN2MS43NjVjMS4zOTYtMi41ODYgNy0yLjc3NyA3IDIuNDc2djYuNzU5eiIvPjwvc3ZnPg=="
                alt="linkedin"
              />
            </a>
          </div>
          <div className="seven columns main-col">
            <h2>
              <kbd>My Skills</kbd>
            </h2>

            <p>{skills}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>
                  <kbd>My Email</kbd>
                </h2>
                <p className="address">
                  <span data-hash={email}>
                    {email ?? "shahin[at]zanbaghi.com"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="three columns main-col">
            <form
              action="https://public.herotofu.com/v1/d7630d10-d01b-11ec-a821-6590c8b23a22"
              method="post"
              className="banner-text"
            >
              <div>
                <label htmlFor="message" style={{ color: "white" }}>
                  <b>Your Message</b>
                </label>
                <textarea name="Message" id="message" required />
              </div>
              <div>
                <label htmlFor="email" style={{ color: "white" }}>
                  <b>Your Email</b>
                </label>
                <input name="Email" id="email" type="email" required />
              </div>
              <div>
                <input
                  type="submit"
                  style={{ background: "white", color: "black" }}
                  value="Send"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
